@mixin slider-nav-1 {
  &__prev,
  &__next {
    display: flex;
    width: 30px;
    height: 30px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: #000;
    background-color: #fff;
    font-size: 18px;
    position: absolute;
    z-index: 10;
    top: 50%;
    left: 3%;
    transform: translateY(-50%);
    cursor: pointer;
    @include shadow("");
    transition: 0.3s ease-out;

    &:hover {
      @include shadow("");
      transform: translateY(-50%) scale(1.2);
    }

    &:focus {
      outline: 0;
    }
  }

  &__next {
    left: auto;
    right: 3%;
  }

  &:hover &__prev,
  &:hover &__next {
    background-color: rgba(#fff, 1);
  }

  @media (min-width: 1200px) {
    &__prev {
      left: 0;
      opacity: 0;
    }

    &__next {
      right: 0;
      opacity: 0;
    }

    &:hover &__prev {
      left: 3%;
      opacity: 1;
    }

    &:hover &__next {
      right: 3%;
      opacity: 1;
    }
  }

  @media (max-width: 575px) {
    &__prev,
    &__next {
      background-color: rgba(#fff, 0.5);
    }
  }
}

@mixin slider-nav-2 {
  &__prev,
  &__next {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #fff;
    border: 1px solid $border-color;
    z-index: 10;
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(-50%, -50%);
    transition: 0.3s ease-out;

    &:hover {
      @include shadow("");
      transform: translate(-50%, -50%) scale(1.2);
    }

    &:focus {
      outline: 0;
    }

    &.swiper-button-disabled {
      display: none;
    }
  }

  &__next {
    left: 100%;
  }
}

@mixin partner-slider {
  position: relative;
  z-index: 1;

  &__prev,
  &__next {
    padding: 10px 5px;
    font-size: 18px;
    color: $secondary;
    font-size: 24px;
    color: $primary;
    position: absolute;
    top: 50%;
    left: -40px;
    z-index: 10;
    transform: translateY(-50%);
    cursor: pointer;

    &:focus {
      outline: 0;
    }
  }

  &__next {
    left: auto;
    right: -40px;
  }

  &__frame {
    display: block;
    position: relative;
    border: 1px solid $border-color;
    background-color: $white;

    &::before {
      content: "";
      display: block;
      width: 100%;
      padding-bottom: 60%;
    }

    img {
      max-width: 80%;
      max-height: 80%;
      object-fit: contain;
      object-position: 50% 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  @media (max-width: 575px) {
    &__prev,
    &__next {
      display: flex;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      padding: 0;
      justify-content: center;
      align-items: center;
      background-color: $white;
      border: 1px solid $border-color;
      font-size: 20px;
      line-height: 1;
    }

    &__prev {
      left: -15px;
    }

    &__next {
      right: -15px;
    }
  }
}

.banner-slider {
  position: relative;
  z-index: 1;

  &__nav {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
  }

  &__pagination {
    display: flex;

    .swiper-pagination-bullet {
      width: 8px;
      height: 8px;
      opacity: 1;
      background-color: $white;

      &:not(:last-child) {
        margin-right: 15px;
      }

      &-active {
        background: 0;
        box-shadow: 0 0 0 2px $white;
      }
    }
  }

  &__play {
    display: inline-flex;
    width: 30px;
    height: 30px;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
    cursor: pointer;
    border: 1px solid transparent;
    border-radius: 50%;
    transition: 0.3s;

    &:hover {
      border-color: $white;
    }

    &::before,
    &::after {
      content: "";
      display: block;
      width: 3px;
      height: 12px;
      background-color: $white;
      margin: 0 3px;
      transition: 0.4s ease-out;
    }

    &.active {
      &::before {
        width: auto;
        height: auto;
        background: 0;
        border: 8px solid transparent;
        border-width: 7px 11px;
        border-left-color: $white;
        transform: translateX(26%);
      }

      &::after {
        display: none;
      }
    }
  }

  @media (max-width: 991px) {
    &__nav {
      bottom: 10px;
    }
  }
}

.partner-slider {
  margin: -15px;
  padding: 0 0 50px;
  position: relative;
  z-index: 1;

  &__frame {
    @include frame("rect", 50%);
    @include shadow("");
    border: 10px solid $white;

    img {
      object-fit: contain;
    }
  }

  .swiper-slide {
    padding: 15px;
  }

  &__nav {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
  }

  &__pagination {
    display: flex;

    .swiper-pagination-bullet {
      width: 8px;
      height: 8px;
      opacity: 1;
      background-color: $primary;

      &:not(:last-child) {
        margin-right: 15px;
      }

      &-active {
        background: 0;
        box-shadow: 0 0 0 2px $primary;
      }
    }
  }

  &__play {
    display: inline-flex;
    width: 30px;
    height: 30px;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
    cursor: pointer;
    border: 1px solid transparent;
    border-radius: 50%;
    transition: 0.3s;

    &:hover {
      border-color: $primary;
    }

    &::before,
    &::after {
      content: "";
      display: block;
      width: 3px;
      height: 12px;
      background-color: $primary;
      margin: 0 3px;
      transition: 0.4s ease-out;
    }

    &.active {
      &::before {
        width: auto;
        height: auto;
        background: 0;
        border: 8px solid transparent;
        border-width: 7px 11px;
        border-left-color: $primary;
        transform: translateX(26%);
      }

      &::after {
        display: none;
      }
    }
  }
}
