.news {
  &__frame {
    @include frame("rect", 66.66%);
    @include frame("zoom");
  }

  &__title {
    font-size: 16px;
    font-weight: 700;
    margin: 16px 0 12px;
  }

  &__desc {
    @include max-line(3);
    margin-bottom: 16px;
  }

  &__link {
    display: inline-block;
    color: #333;
    border-bottom: 1px solid $border-color;
    text-transform: uppercase;

    &:hover {
      color: $primary;
      text-decoration: none;
      border-bottom-color: $primary;
    }
  }

  @media (max-width: 575px) {
    &__title {
      font-size: 14px;
      margin: 12px 0 8px;
    }

    &__desc {
      font-size: 12px;
      margin-bottom: 8px;
    }

    &__link {
      font-size: 13px;
    }
  }
}

.product {
  &__frame {
    @include frame("rect", 66.66%);
    @include frame("zoom");
  }

  &__title {
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    margin: 16px 0 12px;
  }

  &__link {
    display: inline-block;
    color: #333;
    border-bottom: 1px solid $border-color;
    text-transform: uppercase;

    &:hover {
      color: $primary;
      text-decoration: none;
      border-bottom-color: $primary;
    }
  }

  @media (max-width: 575px) {
    &__title {
      font-size: 13px;
      margin: 12px 0 8px;
    }

    &__link {
      font-size: 13px;
    }
  }
}
