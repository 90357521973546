.logo {
  display: inline-flex;
  margin: 15px 0;

  img {
    height: 40px;
  }
}

.header {
  position: relative;
  z-index: 100;
  background: $gradient;

  &__inner {
    display: flex;
    align-items: center;
    position: relative;
  }

  &__elements {
    display: flex;
    align-items: center;
    margin-left: auto;
  }

  &__btn {
    display: inline-flex;
    width: 35px;
    height: 35px;
    justify-content: center;
    align-items: center;
    position: relative;
    color: $gray-800;
    font-size: 20px;

    & + & {
      margin-left: 4px;
    }

    &:hover {
      color: $primary;
      text-decoration: none;
    }

    span {
      display: inline-flex;
      width: 15px;
      height: 15px;
      justify-content: center;
      align-items: center;
      background-color: $primary;
      border-radius: 50%;
      font-size: 11px;
      font-weight: 700;
      text-align: center;
      color: $white;
      position: absolute;
      top: 0;
      right: 0;
    }

    img {
      max-height: 16px;
      object-fit: contain;
      object-position: 50% 50%;
    }
  }

  @media (min-width: 1200px) {
    &__btn {
      display: none;
    }
  }
}

.search {
  padding: 16px 20px;
  border-top: 1px solid #aaa;
  background-color: $light;

  .form-control {
    border: 1px solid #ccc;
    border-right: 0;
  }

  .input-group-text {
    background-color: $white;
    border: 1px solid #ccc;
    border-left: 0;
  }

  @media (min-width: 1200px) {
    display: none;
    @include shadow("");
    width: 400px;
    background: $light;
    padding: 10px 16px;
    border: 0;
    position: absolute;
    top: 100%;
    right: 0;
  }
}

.h-contact {
  display: flex;

  &__item {
    color: $white;

    &:hover {
      color: $white;
      text-decoration: none;
    }

    &:not(:last-child) {
      margin-right: 16px;
    }

    span {
      display: none;
    }
  }

  @media (min-width: 1600px) {
    &__item {
      span {
        display: inline-block;
      }
    }
  }
}

.search-btn {
  display: none;

  @media (min-width: 1200px) {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background: 0;
    border: 0;
    position: relative;
    color: $white;
    margin-right: 40px;

    &::after {
      content: "";
      display: block;
      width: 1px;
      height: 20px;
      background: linear-gradient(to bottom, transparent, #fff, transparent);
      position: absolute;
      top: 50%;
      right: -20px;
      transform: translateY(-50%);
    }
  }
}
