.service-cats {
  height: 85px;
  background-color: $light;
  position: relative;
  z-index: 90;

  &__wrapper {
    background-color: $light;
  }

  &.is-fixed &__wrapper {
    @include shadow("xs");
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
  }

  &__list {
    display: flex;
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
  }

  &__item {
    flex: 1;

    &:nth-child(odd) {
      background-color: #eeeeee;
    }
  }

  &__link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 10px;
    color: #333;
    height: 100%;

    &:hover {
      color: #333;
      text-decoration: none;
      box-shadow: inset 0 -3px 0 #000;
    }

    &.active {
      box-shadow: inset 0 -3px 0 $primary;
    }
  }

  &__icon {
    display: flex;
    margin-bottom: 10px;

    img {
      height: 35px;
    }
  }

  &__text {
    font-size: 14px;
    line-height: 20px;
  }

  @media (max-width: 767px) {
    .container {
      max-width: unset;
      padding: 0;
    }
  }

  @media (max-width: 575px) {
    // &__list {
    //   display: block;
    //   white-space: nowrap;
    //   overflow: auto;
    // }

    // &__item {
    //   display: inline-block;
    //   width: 100px;
    //   min-width: 33.33%;
    //   height: 100%;
    // }

    &__link {
      white-space: break-spaces;
    }

    &__text {
      height: 30px;
      font-size: 13px;
      line-height: 1.2;
    }

    &__icon {
      margin-bottom: 5px;

      img {
        height: 30px;
      }
    }
  }
}
