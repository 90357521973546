.preview {
  position: relative;
  z-index: 1;

  &::before {
    content: "";
    display: block;
    width: 100%;
    height: 50%;
    background: linear-gradient(to bottom, transparent, rgba(#000, 0.9));
    position: absolute;
    z-index: 10;
    bottom: 0;
    left: 0;
  }

  &__thumbs {
    width: 100%;
    position: absolute;
    z-index: 11;
    bottom: 60px;
    left: 0;
  }

  @media (max-width: 1399px) {
    &__thumbs {
      bottom: 40px;
    }
  }

  @media (max-width: 1199px) {
    &__thumbs {
      bottom: 0;
    }
  }
}

.preview-slider {
  &__frame {
    @include frame("rect", 45%);
    background-color: $white;
  }
}

.thumb-slider {
  @include slider-nav-2;
  position: relative;

  .swiper-slide {
    border-top: 1px solid $white;
    color: $white;
    text-transform: uppercase;
    padding: 20px 20px 20px 0;
    cursor: pointer;

    &::before {
      content: "";
      display: block;
      width: 100%;
      border-top: 1px solid $primary;
      position: absolute;
      top: -1px;
      left: 0;
      transform-origin: left;
      transform: rotateY(90deg);
      transition: 0;
    }

    &-thumb-active {
      color: $primary;
      position: relative;

      &::before {
        transform: rotateY(0);
        transition: 5.8s linear;
      }
    }
  }

  @media (max-width: 767px) {
    .swiper-slide {
      padding: 10px 10px 10px 0;
    }
  }

  @media (max-width: 575px) {
    .swiper-slide {
      font-size: 13px;
    }
  }
}
