.about {
  background-color: $light;
  position: relative;
  z-index: 1;

  &__content {
    width: 50%;
    padding-right: 30px;
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 30px 16px;
  }

  &__col {
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    margin-bottom: 14px;
  }

  &__link {
    display: inline-block;
    border-bottom: 1px solid #666;
    font-size: 14px;
    text-transform: uppercase;
    color: #333;

    &:hover {
      color: $primary;
      text-decoration: none;
      border-color: $primary;
    }
  }

  &__video {
    width: 50%;
    height: 100%;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 50%;
  }

  &__video-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
    position: absolute;
    top: 0;
    left: 0;
  }

  &__video-overlay {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    transition: 0.4s ease-out;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;

    &:hover {
      background-color: rgba(#000, 0.3);
    }

    img {
      max-height: 40%;
    }
  }

  @media (max-width: 1199px) {
    padding-bottom: 0;

    &__content {
      width: 100%;
      padding-right: 0;
    }

    &__video {
      display: block;
      width: 100%;
      margin-top: 30px;
      position: relative;
      top: auto;
      left: auto;

      &::before {
        content: "";
        display: block;
        width: 100%;
        padding-bottom: 56.25%;
      }
    }
  }

  @media (max-width: 767px) {
    &__grid {
      grid-template-columns: 1fr 1fr;
    }
  }
}
