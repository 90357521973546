.post {
  &-title {
    font-size: 30px;
    font-weight: 700;
    line-height: 1.2;
    text-align: center;
    margin-bottom: 16px;
  }

  &-info {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    color: #999;
    margin-bottom: 16px;
  }

  &-sapo {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 16px;
  }

  @media (max-width: 991px) {
    &-title {
      font-size: 24px;
    }
  }
}
