.pd-nav {
  background: $gradient;
  position: relative;
  z-index: 50;
  height: 64px;

  &__wrapper {
    background: $gradient;
  }

  &.is-fixed &__wrapper {
    width: 100%;
    @include shadow("xs");
    position: fixed;
    top: 0;
    left: 0;
  }

  &__list {
    display: block;
    white-space: nowrap;
    overflow: auto;
  }

  &__item {
    display: inline-block;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    color: $white;
    padding: 20px 0;
    position: relative;

    &:hover {
      color: $white;
      text-decoration: none;
    }

    &:not(:last-child) {
      margin-right: 50px;
    }

    &::after {
      content: "";
      display: block;
      width: 100%;
      border-bottom: 1px solid $white;
      position: absolute;
      bottom: 16px;
      left: 0;
      transition: 0.4s ease-out;
      transform: rotateY(90deg);
    }

    &:hover,
    &.active {
      &::after {
        transform: rotateY(0);
      }
    }
  }

  &__inner {
    display: flex;
    align-items: center;
  }

  &__btn {
    display: inline-block;
    font-size: 16px;
    line-height: 43px;
    font-weight: 700;
    padding: 0 34px;
    text-transform: uppercase;
    border-radius: 4px;
    border: 1px solid $white;
    margin-left: auto;
    color: $white;

    &:hover {
      color: $white;
      text-decoration: none;
    }
  }

  @media (max-width: 991px) {
    &__btn {
      padding: 0 20px;
      line-height: 38px;
      font-size: 14px;
    }
  }

  @media (max-width: 767px) {
    &__item {
      &:not(:last-child) {
        margin-right: 20px;
      }
    }

    &__btn {
      position: fixed;
      bottom: 15px;
      left: 70px;
      line-height: 40px;
      border: 0;
      background: $gradient;
      @include shadow("");
    }
  }
}

.gallery {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 8px;

  &__item {
    @include frame("rect", 66.66%);
    @include frame("zoom");
  }

  @media (max-width: 767px) {
    grid-template-columns: 1fr 1fr;
  }
}

.pd-intro {
  padding: 40px 0;
  background: url("../images/pd-intro-bg.jpg") center/cover no-repeat;

  &__title {
    font-size: 36px;
    font-weight: 700;
    text-transform: uppercase;
    color: $white;
    line-height: 1.2;
    margin-bottom: 20px;
  }

  &__content {
    font-size: 16px;

    &,
    a,
    a:hover {
      color: $white;
    }

    ul,
    ol {
      padding-left: 17px;
      margin-bottom: 24px;

      &:last-child {
        margin-bottom: 0;
      }

      li {
        &:not(:last-child) {
          margin-bottom: 6px;
        }
      }
    }
  }

  @media (max-width: 1599px) {
    &__title {
      font-size: 30px;
    }
  }

  @media (max-width: 991px) {
    padding: 30px 0;

    &__title {
      font-size: 24px;
    }

    &__content {
      font-size: 14px;
    }
  }
}
