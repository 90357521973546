.banner {
  display: flex;
  flex-direction: column;
  height: 220px;
  justify-content: center;
  position: relative;
  z-index: 1;

  &::before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    background-color: rgba(#000, 0.4);
  }

  &__title {
    font-size: 30px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    line-height: 1.2;
    position: relative;
    color: $white;
    margin: 0;
  }

  &__desc {
    color: $white;
    text-align: center;
    margin-top: 12px;
  }

  @media (max-width: 767px) {
    height: 200px;

    &__title {
      font-size: 24px;
    }
  }

  @media (max-width: 575px) {
    &__title {
      font-size: 20px;
    }
  }
}
