.section {
  padding: 40px 0;

  &__title {
    font-size: 30px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    position: relative;
    padding-bottom: 10px;
    margin-bottom: 30px;

    &::before {
      content: "";
      display: block;
      width: 100px;
      border-bottom: 1px solid #ccc;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }

    &::after {
      content: "";
      display: block;
      width: 25px;
      height: 5px;
      position: absolute;
      bottom: -2px;
      left: 50%;
      transform: translateX(-50%);
      background-color: $primary;
      border-radius: 3px;
    }
  }

  @media (max-width: 991px) {
    padding: 30px 0;

    &__title {
      font-size: 24px;
    }
  }
}

.section-2 {
  background: $light;
  padding: 40px 0;

  &__title {
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 24px;

    &--border {
      border-bottom: 1px solid #707070;
      padding-bottom: 10px;
    }
  }

  @media (max-width: 1599px) {
    &__title {
      font-size: 30px;
    }
  }

  @media (max-width: 991px) {
    padding: 30px 0;

    &__title {
      font-size: 24px;
    }
  }
}
