.footer {
  background-color: #333333;
  padding: 45px 0 0;

  &__grid {
    display: grid;
    grid-template-columns: 1fr 180px 260px 190px;
    grid-gap: 30px;
  }

  &__content {
    &,
    a,
    a:hover {
      color: $white;
    }
  }

  &__title {
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    color: $white;
    margin-bottom: 16px;

    &:not(:first-child) {
      margin-top: 20px;
    }
  }

  &__end {
    background-color: #000000;
    margin-top: 36px;
  }

  &__end-inner {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 15px 0;
  }

  &__copyright {
    color: $white;
  }

  @media (max-width: 1199px) {
    &__grid {
      grid-template-columns: 1fr 1fr;
    }
  }

  @media (max-width: 767px) {
    &__grid {
      grid-gap: 30px 20px;
    }

    &__col {
      &:first-child {
        grid-column: span 2;
      }
    }

    &__end-inner {
      display: block;
    }

    &__copyright {
      margin-bottom: 15px;
    }
  }
}

.f-menu {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;

  &__item {
    &:not(:last-child) {
      margin-bottom: 4px;
    }
  }

  &__link {
    color: $white;

    &:hover {
      color: $white;
    }
  }
}

.f-contact {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;

  &__item {
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  &__link {
    color: $white;

    &:hover {
      color: $white;
    }
  }
}

.f-social {
  display: flex;
  align-items: center;

  &__item {
    display: flex;

    &:not(:last-child) {
      margin-right: 24px;
    }

    img {
      height: 40px;
    }
  }
}
